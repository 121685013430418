import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import { translations } from "../../translations";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const [language, setLanguage] = useState("Français");
  const t = translations[language];

  function scrollToClass(className) {
    const element = document.querySelector(`.${className}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <a className="pointer flexNullCenter" href="/#">
            <Logo src='./logo2.png' alt="Logo" />
          </a>
          <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>xxl web solutions</h1>
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <a
            onClick={() => {
              toggleSidebar(!sidebarOpen);
              scrollToClass("home");
            }}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            href="/#"
            offset={-60}
          >
            {t.home}
          </a>
        </li>
        <li className="semiBold font15 pointer">
          <a
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            href="/#services"
            offset={-60}
          >
            {t.services}
          </a>
        </li>
        <li className="semiBold font15 pointer">
          <a
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            href="/#projects"
            offset={-60}
          >
            {t.projects}
          </a>
        </li>
        <li className="semiBold font15 pointer">
            <a
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: '10px 15px' }}
              href="/#equipe"
              offset={-80}
            >
                {t.team}
            </a>
        </li>
        <li className="semiBold font15 pointer">
          <a
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            href="/#blog"
            offset={-60}
          >
            {t.blog}
          </a>
        </li>
        <li className="semiBold font15 pointer">
          <a
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            href="/#pricing"
            offset={-60}
          >
            {t.pricing}
          </a>
        </li>
        <li className="semiBold font15 pointer">
            <a
                onClick={() => toggleSidebar(!sidebarOpen)}
                activeClass="active"
                className="whiteColor"
                style={{ padding: '10px 15px' }}
                href="/#investment"
                offset={-80}
            >
                {t.invest}
            </a>
        </li>
        <li className="semiBold font15 pointer">
          <a
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="/#contact"
            offset={-60}
          >
            {t.contact}
          </a>
        </li>
      </UlStyle>
      <UlStyle className="flexSpaceCenter">
        <li className="semiBold font15 pointer">
            <a
                href="/login"
                className="whiteColor"
                style={styleBut}
            >
                {t.login}
            </a>
        </li>
        <li className="semiBold font15 pointer flexCenter">
            <a
                href="/register"
                className="whiteColor"
                style={styleBut}
            >
                {t.getStarted}
            </a>
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const styleBut = { padding: '10px 15px', backgroundColor: "white", color: "inherit", borderRadius: "10px" };

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;

const Logo = styled.img`
  height: 40px;
  width: auto;
  @media (max-width: 768px) {
    height: 40px;
  }
  @media (max-width: 480px) {
    height: 30px;
  }
`;
