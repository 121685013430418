import React from "react";
import styled from "styled-components";
import { useTranslation } from '../../TranslationContext';

export default function Investment() {
  const { t } = useTranslation();

  return (
    <Wrapper id="investment">
      <SectionContainer>
        <Container>
          <ContentWrapper>
            <TextContent>
              <h2 className="font40 extraBold">POURQUOI CHOISIR XXL WEB TECHNOLOGY ?</h2>
              <p className="font12">
                Le Digital Report 2024 révèle une croissance significative de l'écosystème numérique. Plus de 5 milliards
                de personnes, soit 62,3 % de la population mondiale, sont désormais des utilisateurs actifs de médias sociaux,
                avec une augmentation de 266 millions en un an (+5,6 %). L'utilisateur moyen passe 2 heures et 23 minutes
                par jour sur les réseaux sociaux, avec TikTok en tête en termes de temps passé (34 heures par mois sur
                Android), suivi de YouTube (28 heures). Instagram est désormais la plateforme favorite à l'échelle mondiale,
                préférée par 16,5 % des internautes.
              </p>
              <StyledButtonContainer>
                <StyledButton href="inv">Lire la suite</StyledButton>
              </StyledButtonContainer>
            </TextContent>
          </ContentWrapper>
        </Container>
      </SectionContainer>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const SectionContainer = styled.div`
  background-color: #f7f7f7;
  padding: 80px 0;
`;

const Container = styled.div`
  width: 85%;
  margin: 0 auto;
  @media (max-width: 1160px) {
    width: 90%;
  }
  @media (max-width: 860px) {
    width: 95%;
  }
`;

const ContentWrapper = styled.div`
  margin: 80px 0;
  padding: 60px;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center; /* Centre le contenu */
  @media (max-width: 860px) {
    padding: 30px;
  }
`;

const TextContent = styled.div`
  width: 100%;
  p {
    max-width: 600px;
    margin: 0 auto; /* Centre le paragraphe */
    line-height: 1.6;
    color: #666;
  }
  h2 {
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 10px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
`;

const StyledButtonContainer = styled.div`
  max-width: 150px;
  margin: 30px auto 0 auto;
  text-align: center;
`;

const StyledButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #df0202;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    color: #ffffff;
  }
`;
