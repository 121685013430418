import React, { createContext, useContext, useState, useEffect } from 'react';
import { translations } from './translations'; // Assure-toi que le chemin est correct

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState('Français');

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  const t = translations[language] || translations['Français']; // Valeur par défaut

  return (
    <TranslationContext.Provider value={{ language, handleLanguageChange, t }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => useContext(TranslationContext);
