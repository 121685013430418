import React from "react";
import styled from "styled-components";
import { useTranslation } from '../../TranslationContext';

export default function Projects() {
  const { t } = useTranslation();
  const items = [
    { title: 'Boutik santé', description: t.descBs },
    { title: 'Elisée', description: t.desElise },
    { title: 'Xxl-lockimmo', description: t.deslocimmo },
    { title: 'Xxl-connexion', description: t.xxlCon },
    { title: 'Xxl Web Design Creation', description: t.desWeb },
    { title: 'Xxl-Web-Technology-cameroun', description: t.desWt, go: "https://xxl-webtechnologycam.com/" },
  ];

  return (
    <Wrapper id="projects">
      <div className="container">
        <HeaderInfo>
          <h1 className="font40 extraBold">Nos réalisations</h1>
        </HeaderInfo>
        <ListContainer>
          {items.map((item, index) => (
            <ListItem key={index}>
              <IconWrapper>
                <Icon>🌟</Icon>
              </IconWrapper>
              <ContentWrapper>
                <Title>{item.title}</Title>
                <Description>{item.description}</Description>
                {item.go && (
                  <ExternalLink href={item.go} target="_blank" rel="noopener noreferrer">
                    Voir le site
                  </ExternalLink>
                )}
              </ContentWrapper>
            </ListItem>
          ))}
        </ListContainer>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 3rem 0;
  background-color: #f9f9f9;
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  background-color: #e0e0e0;
  padding: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const Icon = styled.span`
  font-size: 24px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #333;
`;

const Description = styled.p`
  margin: 5px 0 0;
  font-size: 16px;
  color: #555;
`;

const ExternalLink = styled.a`
  margin-top: 10px;
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
