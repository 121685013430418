import React, { useState } from 'react';
import { Card, Col, Row, Typography, List, Avatar, Tabs, Form, Input, Button, Badge, Breadcrumb, Select } from 'antd';
import styled from 'styled-components';
import Layout from "../components/layout/Layout";

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

// Constantes avec des données fictives
const fakeUser = {
  avatar: 'https://randomuser.me/api/portraits/men/75.jpg',
  name: 'Jean Dupont',
  email: 'jean.dupont@example.com',
  phone: '0123456789',
  registrationDate: '01 Janvier 2020'
};

const fakeProjects = [
  {
    title: 'Projet Alpha',
    description: 'Description du projet Alpha.',
    type: 'Développement',
    logo: 'https://via.placeholder.com/40?text=D',
    status: 'En cours',
    creationDate: '01 Septembre 2023',
    deliveryDate: '15 Décembre 2023'
  },
  {
    title: 'Projet Beta',
    description: 'Description du projet Beta.',
    type: 'Design',
    logo: 'https://via.placeholder.com/40?text=G',
    status: 'Complété',
    creationDate: '15 Mars 2023',
    deliveryDate: '30 Juin 2023'
  },
  {
    title: 'Projet Gamma',
    description: 'Description du projet Gamma.',
    type: 'Recherche',
    logo: 'https://via.placeholder.com/40?text=R',
    status: 'Non commencé',
    creationDate: '01 Octobre 2023',
    deliveryDate: '31 Janvier 2024'
  }
];

const fakeNotifications = [
  { id: 1, content: 'Votre projet Alpha a été mis à jour.', date: '02 Septembre 2023' },
  { id: 2, content: 'Nouvelle notification pour le projet Beta.', date: '01 Septembre 2023' },
  { id: 3, content: 'Projet Gamma en attente.', date: '31 Août 2023' },
];

const Dashboard = () => {
  const [selectedType, setSelectedType] = useState('Tous');

  // Filtrer les projets en fonction du type sélectionné
  const filteredProjects = selectedType === 'Tous'
    ? fakeProjects
    : fakeProjects.filter(project => project.type === selectedType);

  // Obtenir la liste des types de projets
  const projectTypes = ['Tous', ...new Set(fakeProjects.map(p => p.type))];

  return (
    <Layout>
      <Container>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><a href="/">Accueil</a></Breadcrumb.Item>
          <Breadcrumb.Item>Tableau de bord</Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16}>
          <Col xs={24} sm={8} md={8}>
            <Card bordered={false} style={{ minHeight: '300px' }}>
              <UserInfo>
                <Avatar size={64} src={fakeUser.avatar} />
                <UserDetails>
                  <Title level={4}>{fakeUser.name}</Title>
                  <Text>Email: {fakeUser.email}</Text><br />
                  <Text>Téléphone: {fakeUser.phone}</Text><br />
                  <Text>Inscrit depuis: {fakeUser.registrationDate}</Text>
                </UserDetails>
              </UserInfo>
            </Card>
          </Col>
          {/* Tabs for Projects, User Info, Update Info, Password Update, Payment Info, and Notifications */}
          <Col style={{ minHeight: "50rem" }} xs={24} sm={16} md={16}>
            <Card style={{ minHeight: '300px' }}>
              <Tabs defaultActiveKey="1" size="large">
                <TabPane tab="Projets" key="1">
                  <Select
                    defaultValue="Tous"
                    style={{ width: '30%', marginBottom: '16px' }}
                    onChange={value => setSelectedType(value)}
                  >
                    {projectTypes.map(type => (
                      <Option key={type} value={type}>{type}</Option>
                    ))}
                  </Select>
                  <List
                    itemLayout="horizontal"
                    dataSource={filteredProjects}
                    renderItem={project => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<Avatar src={project.logo} />}
                          title={
                            <>
                              <Title level={4}>{project.title}</Title>
                              <Badge
                                count={project.status}
                                style={{
                                  backgroundColor: getStatusColor(project.status),
                                  fontSize: '0.85rem',
                                  padding: '0rem 0.75rem',
                                }}
                              />
                            </>
                          }
                          description={
                            <>
                              <Text>{project.description}</Text><br />
                              <Text>Date de création : {project.creationDate}</Text><br />
                              <Text>Date de livraison : {project.deliveryDate}</Text>
                            </>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </TabPane>
                <TabPane tab="Informations" key="2">
                  <Card>
                    <UserInfo>
                      <Avatar size={64} src={fakeUser.avatar} />
                      <UserDetails>
                        <Title level={4}>{fakeUser.name}</Title>
                        <Text>Email: {fakeUser.email}</Text><br />
                        <Text>Téléphone: {fakeUser.phone}</Text><br />
                        <Text>Inscrit depuis: {fakeUser.registrationDate}</Text>
                      </UserDetails>
                    </UserInfo>
                  </Card>
                </TabPane>
                <TabPane tab="Mise à jour" key="3">
                    <Col xs={24} sm={12} md={12}>
                        <Form layout="vertical">
                            <Form.Item label="Nom">
                                <Input style={{ height: "3rem" }} defaultValue={fakeUser.name} />
                            </Form.Item>
                            <Form.Item label="Email">
                                <Input style={{ height: "3rem" }} defaultValue={fakeUser.email} />
                            </Form.Item>
                            <Form.Item label="Téléphone">
                                <Input style={{ height: "3rem" }} defaultValue={fakeUser.phone} />
                            </Form.Item>
                            <Form.Item label="Date d'inscription">
                                <Input style={{ height: "3rem" }} defaultValue={fakeUser.registrationDate} disabled />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary">Mettre à jour</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </TabPane>
                <TabPane tab="Sécurité" key="4">
                    <Col xs={24} sm={12} md={12}>
                        <Form layout="vertical">
                            <Form.Item label="Ancien mot de passe">
                                <Input.Password style={{ height: "3rem" }} placeholder="Entrez votre ancien mot de passe" />
                            </Form.Item>
                            <Form.Item label="Nouveau mot de passe">
                                <Input.Password style={{ height: "3rem" }} placeholder="Entrez votre nouveau mot de passe" />
                            </Form.Item>
                            <Form.Item label="Confirmer le nouveau mot de passe">
                                <Input.Password style={{ height: "3rem" }} placeholder="Confirmez votre nouveau mot de passe" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary">Mettre à jour le mot de passe</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </TabPane>
                <TabPane tab="Paiement" key="5">
                  <Col xs={24} sm={12} md={12}>
                    <Form layout="vertical">
                        <Form.Item label="Numéro de carte">
                            <Input style={{ height: "3rem" }} placeholder="1234 5678 9123 4567" />
                        </Form.Item>
                        <Form.Item label="Nom sur la carte">
                            <Input style={{ height: "3rem" }} placeholder="Jean Dupont" />
                        </Form.Item>
                        <Form.Item label="Date d'expiration">
                            <Input style={{ height: "3rem" }} placeholder="MM/AA" />
                        </Form.Item>
                        <Form.Item label="CVC">
                            <Input style={{ height: "3rem" }} placeholder="123" />
                        </Form.Item>
                        <Form.Item>
                        <Button type="primary">Mettre à jour les informations de paiement</Button>
                        </Form.Item>
                    </Form>
                  </Col>
                </TabPane>
                <TabPane tab="Notifications" key="6">
                  <List
                    itemLayout="horizontal"
                    dataSource={fakeNotifications}
                    renderItem={notification => (
                      <List.Item>
                        <List.Item.Meta
                          title={<Text>{notification.content}</Text>}
                          description={<Text type="secondary">{notification.date}</Text>}
                        />
                      </List.Item>
                    )}
                  />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

// Fonction pour obtenir la couleur du statut
const getStatusColor = (status) => {
  switch (status) {
    case 'En cours':
      return '#faad14'; // Orange
    case 'Complété':
      return '#52c41a'; // Vert
    case 'Non commencé':
      return '#d9d9d9'; // Gris
    default:
      return '#d9d9d9'; // Gris par défaut
  }
};

// Styled Components
const Container = styled.div`
  padding: 24px 16px;
  background: #f0f2f5;
  max-width: 1200px;
  margin: 0 auto;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const UserDetails = styled.div`
  margin-left: 16px;
`;

export default Dashboard;
