import React from "react";
import styled from "styled-components";
import ProjectBox from "../Elements/ProjectBox";
import { useTranslation } from '../../TranslationContext';

export default function Equipe() {
  const { t } = useTranslation();

  return (
    <Wrapper style={{ paddingTop: "5rem" }} id="equipe">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{t.team}</h1>
            <p className="font13">{t.teamOlo}</p>
          </HeaderInfo>
          <div className="row textCenter">
            <HighlightedCol className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <HighlightedProjectBox
                img="./static/abad.jpg"
                title="Dominique ABAD"
                text={t.presi}
              />
            </HighlightedCol>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img="./static/wd.jpg"
                title="BADR BENCHAGRA"
                text={t.wd}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img="./static/rp.jpg"
                title="YOANN WALDMAN"
                text={t.rd}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img="./static/dv.jpg"
                title="ENZO ESTEVES"
                text={t.dev}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img="./static/cm.jpg"
                title="CELIA BRIANTO"
                text="COMMUNITY MANAGER"
              />
            </div>
            <div style={{ marginBottom: "7rem" }} className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img="./static/mcm.jpg"
                title="MEHDI BRAIKI"
                text={t.mcm}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const HighlightedCol = styled.div`
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const HighlightedProjectBox = styled(ProjectBox)`
  border: 2px solid #f39c12;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  transform: scale(1.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  }
`;

const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;

const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;

const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
