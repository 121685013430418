import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  const navigate = useNavigate();

  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Check Our Pricing</h1>
            <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p>
          </HeaderInfo>
          <TablesWrapper>
            <TableBox>
              <PricingTable
                icon="roller"
                price="à partir de 4000€"
                title="Developpement Web"
                text="Pour les sites web vitrines, nécessitant plus de 5 pages et un portfolio"
                offers={[
                  { name: "Product Offer", cheked: true },
                  { name: "Offer", cheked: true },
                  { name: "Product Offer #2", cheked: false },
                  { name: "Product", cheked: false },
                  { name: "Product Offer", cheked: false },
                ]}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="à partir de 5000€"
                title="Web Design"
                text="Un site vitrine sur mesure, entièrement codé, soit en PHP, Java, symphonie"
                offers={[
                  { name: "Product Offer", cheked: true },
                  { name: "Offer", cheked: true },
                  { name: "Product Offer #2", cheked: true },
                  { name: "Product", cheked: true },
                  { name: "Product Offer", cheked: false },
                ]}
                action={() => navigate("/ap")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="à partir de 7000€"
                title="VIP"
                text="Un site web VIP sur mesure, entièrement codé, soit en PHP, Java, symphonie"
                offers={[
                  { name: "Product Offer", cheked: true },
                  { name: "Offer", cheked: true },
                  { name: "Product Offer #2", cheked: true },
                  { name: "Product", cheked: true },
                  { name: "Product Offer", cheked: true },
                ]}
                action={() => navigate("/ap")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="printer"
                price="à partir de 5000€"
                title="Marketing Digital"
                text="Un site vitrine sur mesure, entièrement codé, soit en PHP, Java, symphonie"
                offers={[
                  { name: "Product Offer", cheked: true },
                  { name: "Offer", cheked: true },
                  { name: "Product Offer #2", cheked: true },
                  { name: "Product", cheked: true },
                  { name: "Product Offer", cheked: false },
                ]}
                action={() => navigate("/mf")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="strong"
                price="Projet personnalise"
                title="xxl-websoluitions.uk"
                text="Un site vitrine sur mesure, entièrement codé, soit en PHP, Java, symphonie"
                offers={[
                  { name: "CRM", cheked: true },
                  { name: "ERP", cheked: true },
                  { name: "Solutions pour finances", cheked: true },
                  { name: "Solutions pour banques", cheked: true },
                  { name: "Et plus encore", cheked: false },
                ]}
                action={() => navigate("/mf")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

const TablesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
`;

const TableBox = styled.div`
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  box-sizing: border-box;

  @media (max-width: 860px) {
    flex: 1 1 100%;
    max-width: 100%;
    margin: 0 auto;
  }
`;
