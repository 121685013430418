import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "monitor":
      getIcon = "./static/webd.png";
      break;
    case "browser":
      getIcon = "./static/code.png";
      break;
    case "printer":
      getIcon = "./static/cn.png";
      break;
    case "shield":
      getIcon = "./static/shield.png";
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconWrapper>
        <img src={getIcon} alt={title} />
      </IconWrapper>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const IconWrapper = styled.div`
  width: 100%;
  max-width: 150px; /* Largeur maximale de l'image */
  margin: 0 auto;
  img {
    width: 100%;
    height: auto; /* Maintient les proportions de l'image */
    object-fit: cover; /* Ajuste l'image si elle est contenue dans une boîte plus petite */
  }
`;

const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  font-size: 1.5rem;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;

const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
`;

const StyledLink = styled.a`
  display: inline-block;
  margin-top: 1.5rem;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;
