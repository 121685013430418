import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from "../components/layout/Layout";
import { Divider, Row, Col, Modal, Breadcrumb, Image as ImgAntd } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

const Investments = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedImage('');
    };

    const Bread = () => (
        <Breadcrumb
          items={[
            {
              href: '/',
              title: <HomeOutlined />,
            },
            {
              title: 'Investissement',
            },
          ]}
        />
      );

    return (
        <Layout>
            <Container>
                <Bread/>
                <Title style={{ marginTop: "4rem", marginBottom: "5rem" }}>XXL WEB TECHNOLOGY</Title>
                <div style={{ textAlign: "center", fontSize: "26px", marginBottom: "8rem" }}>
                    <p style={{ marginBottom: "1rem" }}>100 000 € recherchés</p>
                    <Divider />
                    <p style={{ marginBottom: "1rem" }}>Valorisation post-money : 600 000,00€</p>
                    <Divider />
                    <p style={{ marginBottom: "1rem" }}>prix de l'action : 500,00€</p>
                    <Divider />
                    <p style={{ marginBottom: "1rem" }}>11 intéressés</p>
                </div>
                <Title style={{ fontSize: "40px", marginTop: "5rem" }}>POURQUOI CHOISIR XXL WEB TECHNOLOGY ?</Title>
                <Title style={{ marginTop: "5rem" }}>LE MARCHÉ</Title>
                <Row gutter={[16, 24]} justify="left">
                    <Col xs={24} sm={24} md={16} lg={12} xl={18}>
                        <Text style={{ display: 'block', marginBottom: '1rem' }}>
                            Le <strong>Digital Report 2024</strong> révèle une croissance significative de l'écosystème numérique. Plus de 5 milliards de personnes, soit 62,3 % de la population mondiale, sont désormais des utilisateurs actifs de médias sociaux, avec une augmentation de 266 millions en un an (+5,6 %). L'utilisateur moyen passe 2 heures et 23 minutes par jour sur les réseaux sociaux, avec TikTok en tête en termes de temps passé (34 heures par mois sur Android), suivi de YouTube (28 heures). Instagram est désormais la plateforme favorite à l'échelle mondiale, préférée par 16,5 % des internautes.
                            TikTok atteint une audience publicitaire de 1,56 milliard d'utilisateurs, presque à parité avec Instagram (1,65 milliard). Facebook continue de croître, avec une augmentation de 200 millions d'utilisateurs publicitaires en un an (+10,5 %). LinkedIn dépasse le milliard de membres, avec une forte concentration de jeunes adultes (25-34 ans), tandis que Snapchat atteint 406 millions d'utilisateurs actifs quotidiens, gagnant 100 millions en deux ans.
                        </Text>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <ImgAntd style={{ width: "100%" }} src='./static/invest/img1.png' />
                    </Col>
                </Row>
                <Divider />
                <Row style={{ marginTop: "4rem" }} gutter={[16, 16]}>
                    {['./static/invest/img2.png', './static/invest/img3.png', './static/invest/img4.png', './static/invest/img5.png', './static/invest/img6.png'].map((src, index) => (
                        <Col key={index} xs={24} sm={12} md={6}>
                            <ImgAntd src={src} />
                        </Col>
                    ))}
                </Row>
                <Text style={{ margin: "3rem 0rem" }}>
                    Discord, autre plateforme en forte croissance, pourrait attirer plus de 300 millions d'utilisateurs actifs mensuels, grâce notamment à son association avec Midjourney, un outil d'IA populaire. En parallèle, le temps passé en ligne continue d'augmenter, atteignant 6 heures et 40 minutes par jour en moyenne, tandis que celui consacré à la télévision diminue. Les dépenses publicitaires numériques ont atteint 720 milliards de dollars en 2023, avec une hausse notable des investissements dans les réseaux sociaux et les activités d'influence. Enfin, le rapport souligne la popularité massive des hashtags sur TikTok, notamment #fyp, et l'essor de ChatGPT, la page Wikipédia la plus consultée en 2023.
                </Text>
                <Row gutter={[16, 16]}>
                    {['./static/invest/img7.png', './static/invest/img8.png'].map((src, index) => (
                        <Col key={index} xs={24} sm={12} md={8}>
                            <ImgAntd src={src} />
                        </Col>
                    ))}
                </Row>
                <Text style={{ marginTop: "3rem" }}>
                    S’adresser à des agences digitales et externaliser une partie de l'activité permet de profiter d’une expertise métier complète, de profiter d’un accompagnement et d’outils professionnels. Ces conseils avisés permettent de disposer de véritables savoir-faire en informatique, communication et marketing.
                    <p>Le nombre d’entreprises qui souhaitent effectuer une transition digitale est toujours plus important et est amené à croître davantage :</p>
                </Text>
                <StyledList>
                    <li>Seules 10% des entreprises de 0 à 10 salariés externalisent leurs services digitaux.</li>
                    <li>60% des entreprises d’au moins un salarié n’a jamais effectué de transition digitale.</li>
                </StyledList>
                <img style={{ width: "100%", height: "auto", borderRadius: "20px", margin: "3rem 0rem" }} src="./static/invest/img9.png" />
                <Title style={{ marginTop: "5rem" }}>SOLUTION</Title>
                <Text>
                    <strong>XXL WEB TECHNOLOGY</strong> accompagne les entreprises dans leurs <strong>enjeux de conception et maintenance de leur site web</strong>, de <strong>performance et stratégie numérique</strong>, tout en intégrant les technologies <strong>cloud</strong> et <strong>IoT</strong>.
                </Text>
                <Text>
                    Ainsi elle propose des services de <strong>Conseil & Transformation Numérique</strong>, de <strong>fourniture</strong> et d’<strong>hébergement d’infrastructures</strong>, de <strong>Services Managés</strong> (Support Technologique, Maintien en Condition Opérationnelle) :
                </Text>
                <StyledList>
                    <li>Référencement local et national</li>
                    <li>PPC (pay per click)</li>
                    <li>SMO (marketing Facebook, marketing LinkedIn...)</li>
                    <li>Service de création de liens</li>
                    <li>Suppression de Google Penalty</li>
                    <li>Google Map SEO et SEO vidéo</li>
                    <li>Rédaction de contenu et marketing</li>
                    <li>Stratégie digitale /Télémarketings</li>
                    <li>Conception / Développement Web</li>
                    <li>Développement d'applications mobiles</li>
                </StyledList>
                <Divider />
                <Section>
                    <Subtitle>La méthodologie</Subtitle>
                    <Text>
                        XXL WEB TECHNOLOGY travaille par courtes occurrences et procède à un audit/conseil avant de démarrer des travaux. 
                        L'objectif est d'impliquer le client davantage, de trouver le MVP (Minimum Viable Product) et de procéder en phases.
                    </Text>
                    <Text>
                        La société propose également une activité de conseil selon un positionnement en tant que problem solvers, suivant 
                        la technique de Design Sprint.
                    </Text>
                </Section>
                <Divider />
                <Section>
          <Subtitle>Stratégie de marketing digital</Subtitle>
          <Text>
            XXL WEB TECHNOLOGY aide les agences et les entreprises à accroître leur business par la mise en place de mécanismes 
            de tracking évolués. La récolte des données va nourrir une stratégie digitale et des campagnes à haute performance.
          </Text>
          <Text>
            L’augmentation de la notoriété de la marque permet d’attirer plus de prospects qualifiés sur le site web. Parmi les leviers 
            technologiques utilisés : analytics, optimisation des moteurs de recherche et SEO, tag management, Google Ads, création d’une 
            communauté sur les réseaux sociaux.
          </Text>
        </Section>
        
        <Divider />

        <Section>
          <Subtitle>Conception WEB</Subtitle>
          <Text>
            XXL WEB TECHNOLOGY étudie les projets de ses clients pour y apporter une solution adéquate à leurs besoins. 
            L’accompagnement comporte l'hébergement, la conception ainsi que la maintenance du site web.
          </Text>
        </Section>
        <Divider />

        <Section>
          <Subtitle>Identité visuelle</Subtitle>
          <Text>
            XXL WEB TECHNOLOGY booste l’image de marque de ses partenaires grâce à la communication visuelle, web design, création de logo, 
            charte graphique, carte de visite, photographie et vidéo entreprise.
          </Text>
          <Text>
            Le service comporte notamment la création d’une charte graphique, du site internet (vitrine ou e-commerce) et sa maintenance, 
            mise à disposition d’outil de mailing et newsletter.
          </Text>
        </Section>
        <img style={{ width: "100%", height: "auto", borderRadius: "20px" }} src="./static/invest/img10.png" />

        <Title style={{ marginTop: "5rem" }}>En Développement</Title>

        <Section>
        <img style={{ width: "100%", height: "auto", borderRadius: "20px", marginBottom: "3rem" }} src="./static/invest/img11.png" />
          <Text>
            <strong>XXL WEB TECHNOLOGY</strong> souhaite diversifier son activité en prenant part au <strong>développement d’une intelligence artificielle</strong> (IA) 
            en collaboration avec une équipe de scientifiques diplômés MSc in Biotechnology issus de l'Université de Lille (Parcours 
            Proteomics, OMICS & Systems Biology).
          </Text>
          <Text>
            Ainsi, XXL WEB TECHNOLOGY fait coopérer son équipe et des scientifiques du milieu médical. L'objectif est de développer sous peu, 
            en tant que co-fondateur, une IA dont le projet a été plébiscité lors des conférences suivantes :
          </Text>
          <List>
            <ListItem>«La 10e Conférence internationale sur la biocuration», Stanford - Californie (USA), 26-29 mars 2017.</ListItem>
            <ListItem>«Biocuration IMGT de IG et TR dans IMGT / LIGM-DB et IMGT / GENE-DB»</ListItem>
            <ListItem>«Life Sciences Baltics», Vilnius (Lituanie), 14 septembre 2017</ListItem>
          </List>
        </Section>

        <Section>
          <Text>
            Cette IA se veut une <strong>approche systémique de la biomédecine</strong> pour modéliser les <strong>voies MAP Kinase</strong> sur une base de données et une 
            plateforme de connaissances pour les MAPK.
          </Text>
          <Text>
            L'<strong>intelligence artificielle</strong> est devenue un domaine de recherche international, et la commercialisation de ce logiciel destiné 
            uniquement au <strong>milieu biomédical</strong> est <strong>très attendue par les hôpitaux</strong> du monde entier.
          </Text>
          <Text>
            L'expérience se révèle déjà un <strong>succès</strong>, ne serait-ce que par <strong>l’équipe de scientifiques</strong> qui l’a pensé et son développement par 
            <strong>XXL WEB TECHNOLOGY</strong>.
          </Text>
        </Section>

        <Title style={{ margin: "4rem", marginTop: "5rem" }}>L’ÉQUIPE</Title>
        <SubTitle>Dominique ABAD - Président de XXL WEB TECHNOLOGY</SubTitle>
        <img style={{ width: "28%", height: "auto", borderRadius: "15px", marginBottom: "1rem" }} src="./static/abad.jpg" />

        <Section>
          <Text>
            Dominique ABAD, Président et fondateur de XXL WEB TECHNOLOGY, est un expert reconnu dans le domaine du développement web et des métiers du numérique. Depuis 2013, il se consacre à ces domaines avec passion, après une carrière riche de plus de 30 ans à la tête de plusieurs entreprises.
          </Text>
          <Text>
            Sous sa direction, le chiffre d'affaires de SEATRANS (GB) a connu une croissance notable, passant de 345 £k à 432 £k, avec une augmentation de l'EBE de 21 £k à 28 £k, démontrant ainsi son expertise en gestion d'entreprise.
          </Text>
          <Text>
            Fort de son expérience, Dominique ABAD a également passé quatre années en tant que Directeur d'agence au sein de la société de transport NORBERT DENTRESSANGLE, leader en Europe dans le transport routier de marchandises. Il y a géré un portefeuille de clients internationaux et a contribué à l'expansion de l'entreprise en ouvrant une nouvelle agence à Perpignan et en facilitant son implantation en Espagne.
          </Text>
          <Text>
            Par la suite, Dominique a décidé de se lancer dans la rénovation immobilière en 1995, s'appuyant sur les compétences acquises durant son adolescence dans l'entreprise familiale DOMINGO ABAD à Agen. Il a progressivement constitué un important parc locatif géré par deux sociétés immobilières, la SCI DU STADE et la SCI LES GLAÏEULS.
          </Text>
          <Text>
            En 2018, Dominique a fondé XXL WEB DESIGN CREATION à Baixas, une entreprise qui a évolué pour devenir XXL WEB TECHNOLOGY Ltd, avec un siège social désormais basé au Royaume-Uni depuis 2022. En parallèle, une filiale a été créée au Cameroun sous le nom de XXL WEB TECHNOLOGY SAS, dans une stratégie d'externalisation pour offrir des services de qualité à des prix compétitifs.
          </Text>
        </Section>

        <Divider />

        <Section>
          <SubTitle>Informations Générales</SubTitle>
          <List>
            <ListItem><strong>Raison sociale :</strong> XXL WEB TECHNOLOGY Limited</ListItem>
            <ListItem><strong>Forme :</strong> Société privée à responsabilité limitée</ListItem>
            <ListItem><strong>N° inscription :</strong> 15907945</ListItem>
            <ListItem><strong>Date d'immatriculation :</strong> 20/08/2024</ListItem>
            <ListItem><strong>Capital social :</strong> 15 000,00 £</ListItem>
          </List>
        </Section>

        <Divider />

        <Section>
          <SubTitle>Utilisation des Fonds Recherchés</SubTitle>
          <List>
            <ListItem>30% pour la campagne de communication et marketing.</ListItem>
            <ListItem>70% pour le développement commercial.</ListItem>
          </List>
        </Section>

        <Divider />

        <Section>
          <SubTitle>Table de Capitalisation</SubTitle>
          <Text>La société est détenue à 100% par M. Dominique ABAD.</Text>
        </Section>

        <Divider />

        <Section>
          <SubTitle>Contact</SubTitle>
          <Text>Pour toute question concernant XXL WEB TECHNOLOGY Limited, vous pouvez contacter les équipes responsables de la campagne :</Text>
          <List>
            <ListItem><strong>Tel :</strong> +33 (0) 4.30.82.59.96</ListItem>
            <ListItem><strong>Tél :</strong> +33 (0) 6.73.11.96.45</ListItem>
            <ListItem><strong>Mail :</strong> <a href="mailto:investir@scidustadefr.onmicrosoft.com">investir@scidustadefr.onmicrosoft.com</a></ListItem>
          </List>
        </Section>

        <Divider />

        <Section>
          <SubTitle>Rapport Digital 2024</SubTitle>
          <Text>Plus de 5 Milliards d'Utilisateurs de Médias Sociaux à Travers le Monde !</Text>
        </Section>

        <Title style={{ marginTop: "5rem" }}>Modèle d'Affaires de XXL WEB TECHNOLOGY</Title>
        
        <Section>
          <Text>
            Le modèle économique de XXL WEB TECHNOLOGY repose sur l'accompagnement des entreprises en fonction de leurs besoins spécifiques, de la nature des prestations demandées, et de l'expertise requise.
          </Text>
          
          <Subtitle style={{ marginTop: "1rem" }}>Forfaits Disponibles</Subtitle>
          <PackageList>
            <PackageItem>
              <PackageTitle>Excellence</PackageTitle>
              <PackageDetails>
                À partir de 4000 € : Un site vitrine sur mesure, entièrement codé (PHP, Java, Symfony, etc.), avec un espace administratif.
              </PackageDetails>
            </PackageItem>
            
            <PackageItem>
              <PackageTitle>Business</PackageTitle>
              <PackageDetails>
                À partir de 5000 € : Un pack business pour les sites web vitrines de plus de 5 pages, incluant un portfolio.
              </PackageDetails>
            </PackageItem>
            
            <PackageItem>
              <PackageTitle>VIP</PackageTitle>
              <PackageDetails>
                À partir de 7000 € : Un site web VIP sur mesure, entièrement codé (PHP, Java, Symfony, etc.), avec un espace administratif.
              </PackageDetails>
            </PackageItem>
          </PackageList>
        </Section>

        <Title style={{ marginTop: "5rem" }}>Proposition Financière</Title>
        
        <Section>
          <Text>
            Comme tous les projets présentés aux investisseurs particuliers, celui-ci fait l’objet d’un financement en réseau fermé. Cela signifie que l'entrepreneur donne en priorité la possibilité à son réseau direct et indirect d'investir dans sa société.
          </Text>
          
          <Subtitle style={{ marginTop: "1rem" }}>Retour sur Investissement</Subtitle>
          <Text>
            En échange de votre investissement, vous recevrez (au prorata de la somme investie) des dividendes sur les résultats de la Société. Vous avez également la possibilité de réaliser une plus-value à la revente de vos actions et de défiscaliser une partie de votre investissement à hauteur de 25%.
          </Text>

          <Subtitle style={{ marginTop: "2rem" }}>Historique</Subtitle>
          <List>
            <ListItem>Raison sociale : XXL WEB TECHNOLOGY</ListItem>
            <ListItem>Forme : Private Limited Company</ListItem>
            <ListItem>Company number: 15907945</ListItem>
            <ListItem>Date d'immatriculation : 20 août 2024</ListItem>
            <ListItem>Capital social : £15 000</ListItem>
          </List>

          <Subtitle style={{ marginTop: "2rem" }}>Ventilation des Fonds Recherchés</Subtitle>
          <List>
            <ListItem>30% pour la campagne de communication et marketing</ListItem>
            <ListItem>70% pour le développement commercial</ListItem>
          </List>

          <Subtitle style={{ marginTop: "2rem" }}>Table de Capitalisation</Subtitle>
          <Text>
            La société est détenue par : <br />
            M. Dominique ABAD détient 100% des actions de la société XXL WEB TECHNOLOGY.
          </Text>

          <Subtitle style={{ marginTop: "2rem" }}>Contact</Subtitle>
          <Text>
            Si vous avez plus de questions concernant XXL WEB TECHNOLOGY, vous pouvez contacter les équipes responsables de la campagne :
          </Text>
          <List>
            <ListItem>Tél : +33 (0) 4.30.82.59.96</ListItem>
            <ListItem>Mail : investir@scidustadefr.onmicrosoft.com</ListItem>
          </List>
        </Section>

        <Title style={{ marginTop: "5rem" }}>Comment Investir</Title>

        <Section style={{ marginBottom: "15rem" }}>
          <Subtitle>Mon paiement est-il sécurisé ?</Subtitle>
          <Text>
            La levée de fonds de notre société est réalisée via XXL WEB TECHNOLOGY LTD. Le paiement en ligne est administré par le Prestataire de Paiement Stripe :
          </Text>
          <List>
            <ListItem>Par carte bancaire jusqu'à 1 500€.</ListItem>
            <ListItem>Par prélèvement bancaire au-delà de 1 500€. Vous devrez, dans ce cas, renseigner votre IBAN et BIC pour le prélèvement.</ListItem>
            <ListItem>Procédé simple et sécurisé.</ListItem>
          </List>

          <Subtitle style={{ marginTop: "2rem" }}>Quel intérêt d’utiliser XXL WEB TECHNOLOGY Limited pour investir ?</Subtitle>
          <Text>
            Vous gardez ainsi le suivi de votre investissement dans la même interface et une fois l’investissement réalisé, l’aspect « post-investissement » (communication, suivi des investissements) se fait via votre compte. Plusieurs raisons nous ont convaincus d’utiliser notre plateforme pour lever des fonds, notamment pour protéger les intérêts des investisseurs :
          </Text>
          <List>
            <ListItem>Avec XXL WEB TECHNOLOGY Limited, vous avez la garantie de la traçabilité de votre investissement. XXL WEB TECHNOLOGY Limited conserve dans un coffre-fort électronique et sécurisé pendant 10 ans les documents prouvant votre investissement. Vous y avez accès depuis votre « Tableau de bord ».</ListItem>
            <ListItem>Dans le cas où votre investissement donne droit à une réduction d'impôts, vos attestations sont créées automatiquement et disponibles sur votre « Tableau de bord ».</ListItem>
            <ListItem>Avec XXL WEB TECHNOLOGY Limited, vous recevrez nos reportings directement sur votre compte. Mieux encore, une cohésion et des discussions entre investisseurs pourront avoir lieu.</ListItem>
          </List>

          <Subtitle style={{ marginTop: "2rem" }}>Puis-je investir depuis une société ou ma holding ?</Subtitle>
          <Text>
            Bien sûr, c’est tout aussi simple, c’est pourquoi nous nous appuyons sur la plateforme XXL WEB TECHNOLOGY Limited.
          </Text>

          <Subtitle style={{ marginTop: "2rem" }}>Mon investissement est-il garanti ?</Subtitle>
          <Text>
            Non, votre investissement n’est pas garanti. Il y a un risque de perdre une partie ou la totalité de l'investissement. Il existe également un risque d’illiquidité de votre investissement.
          </Text>

          <Subtitle style={{ marginTop: "2rem" }}>Comment faire pour investir ?</Subtitle>
          <List>
            <ListItem>1) Cliquez sur le bouton « J’INVESTIS MAINTENANT »</ListItem>
            <ListItem>2) Renseignez vos coordonnées (et votre numéro de téléphone) ; important pour votre attestation d’investissement</ListItem>
            <ListItem>3) Procédez au paiement par Carte Bancaire ou Prélèvement Bancaire</ListItem>
            <ListItem>4) Recevez votre attestation d’investissement. Sur ce bulletin figure vos coordonnées et le nombre de parts que vous avez achetées.</ListItem>
          </List>

          <Subtitle style={{ marginTop: "2rem" }}>Est-ce que je reçois un document qui atteste de ma prise de participation ?</Subtitle>
          <Text>
            Oui, le bulletin de souscription reçu par courriel après la clôture de la campagne constitue la preuve de votre investissement.
          </Text>

          <Subtitle style={{ marginTop: "2rem" }}>Quel sera mon rôle à jouer en tant qu'actionnaire ?</Subtitle>
          <Text>
            Votre rôle d’investisseur est essentiellement moral et financier. Vous n’aurez pas de travaux à réaliser pour l’entreprise, ni de compte à rendre. Vous recevrez régulièrement des informations sur les actualités et les avancées de la société. Libre à vous de vous impliquer dans le développement commercial de l'entreprise.
          </Text>

          <Subtitle style={{ marginTop: "2rem" }}>Que se passe-t-il si la levée n'arrive pas à son terme ?</Subtitle>
          <Text>
            La totalité des investisseurs sera remboursée sans frais sous 48H.
          </Text>
        </Section>

        <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
            <ModalImage src={selectedImage} alt="Selected" />
        </Modal>
            </Container>
        </Layout>
    );
};

// Styled Components
const Container = styled.div`
    padding: 24px 16px;
    max-width: 1200px;
    margin: 0 auto;
`;

const Title = styled.h1`
    text-align: center;
    margin-bottom: 24px;
    color: #333;
`;

const Text = styled.div`
    flex: 1;
    font-size: 16px;
    color: #666;
    line-height: 1.6;
`;

const ModalImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 8px;
`;

const StyledList = styled.ul`
    margin-top: 16px;
    padding-left: 20px;
    list-style-type: disc;

    li {
        margin-bottom: 8px;
        font-size: 16px;
        color: #666;
    }
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const Subtitle = styled.h2`
  margin-bottom: 16px;
  color: #555;
`;

const List = styled.ul`
  margin-left: 20px;
  list-style-type: disc;
`;

const ListItem = styled.li`
  font-size: 16px;
  color: #666;
  margin-bottom: 8px;
`;

const SubTitle = styled.h2`
  color: #333;
  margin-bottom: 16px;
`;

const PackageList = styled.div`
  margin-left: 20px;
`;

const PackageItem = styled.div`
  margin-bottom: 16px;
`;

const PackageTitle = styled.h3`
  font-size: 16px;
  color: #000;
  margin-bottom: 8px;
`;

const PackageDetails = styled.p`
  font-size: 16px;
  color: #666;
`;

export default Investments;
