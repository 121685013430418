import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Typography, Select } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import Layout from "../components/layout/Layout";
import styled from "styled-components";

const { Title, Text } = Typography;
const { Option } = Select;

const servicesOptions = [
  { value: 'seo', label: 'SEO (Optimisation pour les moteurs de recherche)', price: 500 },
  { value: 'ppc', label: 'PPC (Publicité au paiement par clic)', price: 300 },
  { value: 'content-marketing', label: 'Marketing de contenu', price: 400 },
  { value: 'social-media', label: 'Marketing sur les réseaux sociaux', price: 250 },
  { value: 'email-marketing', label: 'Marketing par email', price: 200 },
];

const deliveryOptions = [
  { value: 'standard', label: 'Livraison standard (5 jours)', price: 0 },
  { value: 'express', label: 'Livraison express (moins de 7 jours)', price: 75 },
];

const afterSalesOptions = [
  { value: 'none', label: 'Aucun service après-vente', price: 0 },
  { value: '1week', label: 'Service après-vente - 1ère semaine (gratuite)', price: 0 },
  { value: 'additionalWeeks', label: 'Service après-vente - Semaines supplémentaires', price: 20 },
];

export default function MarketingForm() {
  const { control, handleSubmit, register, watch, setValue } = useForm();
  const [totalPrice, setTotalPrice] = useState(0);

  // Fonction pour calculer le prix total
  const calculateTotalPrice = (selectedServices, deliveryType, afterSales) => {
    const servicesPrice = selectedServices.reduce((sum, service) => {
      const serviceOption = servicesOptions.find(option => option.value === service);
      return sum + (serviceOption ? serviceOption.price : 0);
    }, 0);

    const deliveryPrice = deliveryType === 'express' ? 75 : 0;
    const afterSalesPrice = afterSales.value === 'additionalWeeks' ? (afterSales.weeks * 20) : 0;

    setTotalPrice(servicesPrice + deliveryPrice + afterSalesPrice);
  };

  // Écouter les changements dans les services, la livraison et le service après-vente
  const selectedServices = watch('services', []);
  const deliveryType = watch('delivery', 'standard');
  const afterSales = watch('afterSales', { value: 'none', weeks: 0 });

  useEffect(() => {
    calculateTotalPrice(selectedServices, deliveryType, afterSales);
  }, [selectedServices, deliveryType, afterSales]);

  const onSubmit = (data) => {
    console.log(data);
    // Envoyer les données à un serveur ou effectuer une autre action
  };

  return (
    <Layout>
      <Wrapper>
        <div style={{ maxWidth: 600, margin: 'auto', padding: 24 }}>
          <Title level={3}>Formulaire de Projet Marketing Digital</Title>
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Form.Item
              label="Entreprise"
              name="company"
              rules={[{ required: true, message: 'Le nom de l\'entreprise est requis' }]}
            >
              <Input {...register('company')} />
            </Form.Item>
            <Form.Item
              label="Budget prévu"
              name="budget"
            >
              <Input {...register('budget')} />
            </Form.Item>
            <Form.Item
              label="Objectifs du projet"
              name="projectGoals"
              rules={[{ required: true, message: 'Les objectifs du projet sont requis' }]}
            >
              <Input.TextArea rows={4} {...register('projectGoals')} />
            </Form.Item>
            <Form.Item
              label="Services souhaités"
              name="services"
            >
              <Controller
                name="services"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    mode="multiple"
                    placeholder="Sélectionnez les services"
                    style={{ width: '100%' }}
                  >
                    {servicesOptions.map((service) => (
                      <Option key={service.value} value={service.value}>
                        {service.label} - ${service.price}
                      </Option>
                    ))}
                  </Select>
                )}
              />
            </Form.Item>
            <Form.Item
              label="Type de livraison"
              name="delivery"
            >
              <Controller
                name="delivery"
                control={control}
                render={({ field }) => (
                  <Select {...field} placeholder="Sélectionnez le type de livraison" style={{ width: '100%' }}>
                    {deliveryOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label} - ${option.price}
                      </Option>
                    ))}
                  </Select>
                )}
              />
            </Form.Item>
            <Form.Item
              label="Service après-vente"
              name="afterSales"
            >
              <Controller
                name="afterSales"
                control={control}
                render={({ field }) => (
                  <>
                    <Select {...field} placeholder="Sélectionnez le service après-vente" style={{ width: '100%' }}>
                      {afterSalesOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label} - ${option.price}
                        </Option>
                      ))}
                    </Select>
                    {field.value === 'additionalWeeks' && (
                      <Input
                        type="number"
                        min={0}
                        placeholder="Nombre de semaines supplémentaires"
                        style={{ marginTop: 10 }}
                        onChange={(e) => setValue('afterSales', { value: 'additionalWeeks', weeks: parseInt(e.target.value) })}
                      />
                    )}
                  </>
                )}
              />
            </Form.Item>
            <Form.Item
              label="Commentaires supplémentaires"
              name="additionalComments"
            >
              <Input.TextArea rows={4} {...register('additionalComments')} />
            </Form.Item>
            <Form.Item>
              <Text>Total des prix: ${totalPrice}</Text>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Envoyer
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  background: #f5f5f5;
`;
