import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../TranslationContext';
import Footer from '../Sections/Footer';
import TopNavbar from '../Nav/TopNavbar';

export default function Layout({ children }) {
  const [y, setY] = useState(window.scrollY);

  useEffect(() => {
    const handleScroll = () => setY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [y]);

  return (
    <>
      <TopNavbar/>

      <MainContent>{children}</MainContent>

      <Footer />
    </>
  );
}

const MainContent = styled.main`
  margin-top: 80px;
`;
