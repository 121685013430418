import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  message,
  Card as AntCard,
  Row,
  Col
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Layout from "../components/layout/Layout";
import styled from "styled-components";
import axios from "axios";

export default function SubmitProject() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    project_name: "",
    website_type: "",
    features: "",
    pages: [],
    hosting: "",
    language: "",
    file_upload: null,
    budget: "",
    timeline: "",
  });
  const [form] = Form.useForm();
  const [totalPrice, setTotalPrice] = useState(0);

  // Prix des différentes options
  const prices = {
    website_type: {
      corporate: 500,
      ecommerce: 1000,
      portfolio: 300,
      blog: 200,
      other: 0,
    },
    hosting: {
      yes: 200,
      no: 0,
    },
    pages: {
      home: 50,
      about: 30,
      services: 70,
      contact: 40,
      portfolio: 60,
      blog: 20,
    }
  };

  // Fonction pour mettre à jour les champs du formulaire
  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Fonction pour calculer le prix total
  useEffect(() => {
    const websitePrice = prices.website_type[formData.website_type] || 0;
    const hostingPrice = prices.hosting[formData.hosting] || 0;
    const pagesPrice = formData.pages.reduce((acc, page) => acc + (prices.pages[page] || 0), 0);
    setTotalPrice(websitePrice + hostingPrice + pagesPrice);
  }, [formData.website_type, formData.hosting, formData.pages]);

  const onFinish = async () => {
    try {
      const response = await axios.post("/api/submit", formData);
      message.success("Commande soumise avec succès !");
    } catch (error) {
      message.error("Erreur lors de la soumission de la commande !");
    }
  };

  return (
    <Layout>
      <Wrapper>
        <Content>
          <StyledCard title="Soumettre un Projet" bordered={false}>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <ImageWrapper>
                  <img
                    src="https://via.placeholder.com/500x500"
                    alt="Illustration"
                  />
                </ImageWrapper>
              </Col>
              <Col xs={24} md={12}>
                <FormWrapper>
                  <Form
                    form={form}
                    name="submit_project"
                    onFinish={onFinish}
                    layout="vertical"
                  >
                    <h3>
                      Veuillez remplir ce formulaire pour soumettre votre
                      projet. Assurez-vous de fournir toutes les informations
                      nécessaires.
                    </h3>

                    {/* Informations personnelles */}
                    <StyledCard
                      title="Informations personnelles"
                      bordered={false}
                    >
                      <WideFormItem
                        name="name"
                        label="Nom complet"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez entrer votre nom complet !",
                          },
                        ]}
                      >
                        <Input
                          name="name"
                          value={formData.name}
                          onChange={(e) =>
                            handleChange("name", e.target.value)
                          }
                          placeholder="Votre nom complet"
                        />
                      </WideFormItem>

                      <WideFormItem
                        name="email"
                        label="Adresse email"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez entrer votre email !",
                          },
                          {
                            type: "email",
                            message: "Email invalide !",
                          },
                        ]}
                      >
                        <Input
                          name="email"
                          value={formData.email}
                          onChange={(e) =>
                            handleChange("email", e.target.value)
                          }
                          placeholder="Votre adresse email"
                        />
                      </WideFormItem>
                    </StyledCard>

                    {/* Détails du projet */}
                    <StyledCard
                      title="Détails du projet"
                      bordered={false}
                    >
                      <WideFormItem
                        name="project_name"
                        label="Nom du projet"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez entrer le nom de votre projet !",
                          },
                        ]}
                      >
                        <Input
                          name="project_name"
                          value={formData.project_name}
                          onChange={(e) =>
                            handleChange("project_name", e.target.value)
                          }
                          placeholder="Nom de votre projet"
                        />
                      </WideFormItem>

                      <WideFormItem
                        name="website_type"
                        label="Type de site web"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez sélectionner un type de site web !",
                          },
                        ]}
                      >
                        <Select
                          name="website_type"
                          value={formData.website_type}
                          onChange={(value) =>
                            handleChange("website_type", value)
                          }
                          placeholder="Sélectionnez un type de site web"
                        >
                          <Select.Option value="corporate">
                            Site Corporate - 500€
                          </Select.Option>
                          <Select.Option value="ecommerce">
                            Site E-commerce - 1000€
                          </Select.Option>
                          <Select.Option value="portfolio">
                            Portfolio - 300€
                          </Select.Option>
                          <Select.Option value="blog">
                            Blog - 200€
                          </Select.Option>
                          <Select.Option value="other">
                            Autre - 0€
                          </Select.Option>
                        </Select>
                      </WideFormItem>

                      <WideFormItem
                        name="features"
                        label="Fonctionnalités souhaitées"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez décrire les fonctionnalités souhaitées !",
                          },
                        ]}
                      >
                        <Input.TextArea
                          name="features"
                          value={formData.features}
                          onChange={(e) =>
                            handleChange("features", e.target.value)
                          }
                          placeholder="Décrivez les fonctionnalités que vous souhaitez inclure"
                        />
                      </WideFormItem>

                      <WideFormItem
                        name="pages"
                        label="Pages souhaitées"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez sélectionner les pages souhaitées !",
                          },
                        ]}
                      >
                        <Select
                          name="pages"
                          mode="multiple"
                          value={formData.pages}
                          onChange={(value) =>
                            handleChange("pages", value)
                          }
                          placeholder="Sélectionnez les pages souhaitées"
                        >
                          <Select.Option value="home">
                            Page d'accueil - 50€
                          </Select.Option>
                          <Select.Option value="about">
                            À propos - 30€
                          </Select.Option>
                          <Select.Option value="services">
                            Services - 70€
                          </Select.Option>
                          <Select.Option value="contact">
                            Contact - 40€
                          </Select.Option>
                          <Select.Option value="portfolio">
                            Portfolio - 60€
                          </Select.Option>
                          <Select.Option value="blog">
                            Blog - 20€
                          </Select.Option>
                        </Select>
                      </WideFormItem>
                    </StyledCard>

                    {/* Technologie et Hébergement */}
                    <StyledCard
                      title="Technologie et Hébergement"
                      bordered={false}
                    >
                      <WideFormItem
                        name="hosting"
                        label="Souhaitez-vous un hébergement ?"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez indiquer si vous souhaitez un hébergement !",
                          },
                        ]}
                      >
                        <Select
                          name="hosting"
                          value={formData.hosting}
                          onChange={(value) =>
                            handleChange("hosting", value)
                          }
                          placeholder="Choisir l'hébergement"
                        >
                          <Select.Option value="yes">
                            Oui - 200€
                          </Select.Option>
                          <Select.Option value="no">
                            Non - 0€
                          </Select.Option>
                        </Select>
                      </WideFormItem>

                      <WideFormItem
                        name="technology"
                        label="Technologie à utiliser"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez sélectionner une technologie !",
                          },
                        ]}
                      >
                        <Select
                          name="technology"
                          value={formData.technology}
                          onChange={(value) =>
                            handleChange("technology", value)
                          }
                          placeholder="Sélectionnez une technologie"
                        >
                          <Select.Option value="php">PHP</Select.Option>
                          <Select.Option value="java">Java</Select.Option>
                          <Select.Option value="symfony">
                            Symfony
                          </Select.Option>
                        </Select>
                      </WideFormItem>

                      <WideFormItem
                        name="file_upload"
                        label="Uploader un fichier"
                        valuePropName="fileList"
                        getValueFromEvent={(e) =>
                          Array.isArray(e) ? e : e && e.fileList
                        }
                      >
                        <Upload
                          name="file"
                          action="/upload"
                          listType="picture"
                          beforeUpload={() => false}
                          onChange={({ file }) =>
                            handleChange("file_upload", file)
                          }
                        >
                          <Button icon={<UploadOutlined />}>Uploader</Button>
                        </Upload>
                      </WideFormItem>
                    </StyledCard>

                    {/* Budget et Délai */}
                    <StyledCard
                      title="Budget et Délai"
                      bordered={false}
                    >
                      <WideFormItem
                        name="budget"
                        label="Budget"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez entrer votre budget !",
                          },
                        ]}
                      >
                        <Input
                          name="budget"
                          value={formData.budget}
                          onChange={(e) =>
                            handleChange("budget", e.target.value)
                          }
                          placeholder="Budget estimé"
                        />
                      </WideFormItem>

                      <WideFormItem
                        name="timeline"
                        label="Délai souhaité"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez indiquer votre délai souhaité !",
                          },
                        ]}
                      >
                        <Input
                          name="timeline"
                          value={formData.timeline}
                          onChange={(e) =>
                            handleChange("timeline", e.target.value)
                          }
                          placeholder="Délai souhaité pour la réalisation"
                        />
                      </WideFormItem>

                      <div>Total estimé: {totalPrice}€</div>
                    </StyledCard>

                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Soumettre
                      </Button>
                    </Form.Item>
                  </Form>
                </FormWrapper>
              </Col>
            </Row>
          </StyledCard>
        </Content>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  background: #f5f5f5;
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const StyledCard = styled(AntCard)`
  margin-bottom: 24px;
`;

const FormWrapper = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const WideFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const ImageWrapper = styled.div`
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
  }
`;
