import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Button, Avatar } from 'antd';
import { translations } from "../../translations";
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from "../../TranslationContext";

const userMenu = (
  <Menu>
    <Menu.Item key="profile">
      <RouterLink to="/dashboard">Mon profil</RouterLink>
    </Menu.Item>
    <Menu.Item key="settings">
      <RouterLink to="/settings">Paramètres</RouterLink>
    </Menu.Item>
    <Menu.Item key="logout">
      <RouterLink to="/logout">Déconnexion</RouterLink>
    </Menu.Item>
  </Menu>
);

const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="32"
    height="32"
    fill="currentColor"
    style={{ cursor: 'pointer' }}
  >
    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-3.31 0-6 2.69-6 6v1h12v-1c0-3.31-2.69-6-6-6z" />
  </svg>
);

export default function Common (){
  const [y, setY] = useState(window.scrollY);
  const [language, setLanguage] = useState("Français");

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }

    const handleScroll = () => setY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [y]);

  const t = translations[language];

  return (
    <>
      <UlWrapper className="flexNullCenter">
        <LiCorp language={language} />
      </UlWrapper>
      <UlWrapperRight className="flexNullCenter">
        <div style={{ paddingRight: '2rem' }}>
          <TranslateDropDown />
        </div>
        <li className="semiBold font15 pointer">
          <a
            href="/login"
            className="radius8"
            style={{ padding: '10px 15px', width: "max-content" }}
          >
            {t.login}
          </a>
        </li>
        <li className="semiBold font15 pointer flexCenter">
          <a
            href="/register"
            className="radius8"
            style={{ padding: '10px 15px', width: "max-content" }}
          >
            {t.getStarted}
          </a>
        </li>
        {/* User Avatar Dropdown */}
        <div style={{ paddingLeft: '2rem' }}>
          <Dropdown overlay={userMenu} trigger={['click']}>
            <Avatar icon={<UserIcon />} style={{ cursor: 'pointer' }} />
          </Dropdown>
        </div>
      </UlWrapperRight>
    </>
  );
}

export function TranslateDropDown() {
  //const [language, setLanguage] = useState("Français");
  const { language, handleLanguageChange } = useTranslation();

  const handleLanguageChangeMenu = ({ key }) => {
    handleLanguageChange(key);
  };

  const languageMenu = (
    <Menu onClick={handleLanguageChangeMenu}>
      <Menu.Item key="Français">
        <img
          src="./france.png"
          alt="French Flag"
          style={{ width: "20px", marginRight: "8px" }}
        />
        Français
      </Menu.Item>
      <Menu.Item key="English">
        <img
          src="./uk.png"
          alt="English Flag"
          style={{ width: "20px", marginRight: "8px" }}
        />
        English
      </Menu.Item>
    </Menu>
  );
  
  return (
    <Dropdown overlay={languageMenu} trigger={['click']}>
      <Button>
        {language} <DownOutlined />
      </Button>
    </Dropdown>
  );
}

export function LiCorp({ language }) {
    const t = translations[language];
    return (<><li className="semiBold font15 pointer">
        <a
            activeClass="active"
            style={{ padding: '10px 15px' }}
            href="/#"
            smooth={true}
            offset={-80}
        >
            {t.home}
        </a>
    </li><li className="semiBold font15 pointer">
            <a
                activeClass="active"
                style={{ padding: '10px 15px' }}
                href="/#services"
                smooth={true}
                offset={-80}
            >
                {t.services}
            </a>
        </li><li className="semiBold font15 pointer">
            <a
                activeClass="active"
                style={{ padding: '10px 15px' }}
                href="/#projects"
                spy={true}
                smooth={true}
                offset={-80}
            >
                {t.projects}
            </a>
        </li><li className="semiBold font15 pointer">
            <a
                activeClass="active"
                style={{ padding: '10px 15px' }}
                href="/#equipe"
                smooth={true}
                offset={-80}
            >
                Equipes
            </a>
        </li><li className="semiBold font15 pointer">
            <a
                activeClass="active"
                style={{ padding: '10px 15px' }}
                href="/#blog"
                offset={-80}
            >
                {t.blog}
            </a>
        </li><li className="semiBold font15 pointer">
            <a
                activeClass="active"
                style={{ padding: '10px 15px' }}
                href="/#pricing"
                offset={-80}
            >
                {t.pricing}
            </a>
        </li><li className="semiBold font15 pointer">
            <a
                activeClass="active"
                style={{ padding: '10px 15px' }}
                href="/#investment"
                smooth={true}
                offset={-80}
            >
                {t.invest}
            </a>
        </li><li className="semiBold font15 pointer">
            <a
                activeClass="active"
                style={{ padding: '10px 15px' }}
                href="/#contact"
                smooth={true}
                offset={-80}
            >
                {t.contact}
            </a>
        </li></>);
}

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;