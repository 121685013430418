import React from "react";
import styled from "styled-components";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { useTranslation } from '../../TranslationContext';
import ReactPlayer from 'react-player';

export default function Header() {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper className="container flexSpaceCenter home">
        <LeftSide className="flexCenter">
          <div>
            <Title>{t.motIntro}</Title>
            <HeaderP>{t.phraseIntro}</HeaderP>
          </div>
        </LeftSide>
        <RightSide>
          <ImageWrapper>
            <Img src={'./static/present.png'} alt="office" />
            <QuoteWrapper className="flexCenter darkBg radius8">
              <QuotesWrapper>
                <QuotesIcon />
              </QuotesWrapper>
              <div>
                <QuoteText>
                  <em>Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it.</em>
                </QuoteText>
                <QuoteAuthor>{`Dominique ABAD, ${t.pdg}`}</QuoteAuthor>
              </div>
            </QuoteWrapper>
            <DotsWrapper>
              <Dots />
            </DotsWrapper>
          </ImageWrapper>
          <GreyDiv className="lightBg"></GreyDiv>
        </RightSide>
      </Wrapper>
      <WrapperVid className="container">
        <ContentWrapper>
          <VideoBlock>
            <ReactPlayer width="100%" height="auto" controls={true} url="./static/intro.mp4" />
          </VideoBlock>
          <TextBlock>
            <Subtitle>{t.moiTwo}</Subtitle>
          </TextBlock>
        </ContentWrapper>
      </WrapperVid>
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  display: flex;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 800;
  color: #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  @media (max-width: 960px) {
    font-size: 2.5rem;
  }
  @media (max-width: 560px) {
    font-size: 2rem;
  }
`;

const HeaderP = styled.p`
  font-size: 1.125rem;
  color: #666;
  line-height: 1.8;
  max-width: 470px;
  margin: 0 auto;
  padding: 15px 0 50px 0;
  @media (max-width: 960px) {
    font-size: 1rem;
    text-align: center;
    max-width: 100%;
  }
`;

const QuoteText = styled.p`
  font-size: 1rem;
  color: #fff;
  line-height: 1.6;
  margin: 0;
  @media (max-width: 960px) {
    font-size: 0.875rem;
  }
`;

const QuoteAuthor = styled.p`
  font-size: 0.875rem;
  color: #f39c12;
  text-align: right;
  margin-top: 10px;
  @media (max-width: 960px) {
    font-size: 0.75rem;
    text-align: center;
  }
`;

const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;

const Img = styled.img`
  border-radius: 8px;
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;

const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;

const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;

const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;

const WrapperVid = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
  min-height: 540px;
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  max-width: 1200px;
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const VideoBlock = styled.div`
  flex: 1;
  max-width: 800px;
  @media (max-width: 960px) {
    max-width: 100%;
  }
`;

const TextBlock = styled.div`
  flex: 1;
  padding: 20px;
  @media (max-width: 960px) {
    text-align: center;
  }
`;

const Subtitle = styled.h2`
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
  margin: 0;
  @media (max-width: 960px) {
    font-size: 1.2rem;
  }
`;
