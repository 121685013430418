import React from "react";
import { Form, Input, Button, Typography } from "antd";
import styled from "styled-components";

const { Title } = Typography;

const SignupPage = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Container>
      <Content>
        <LogoWrapper>
          <a href="/"><Logo src="./logo2.png" alt="Logo" /></a>
        </LogoWrapper>
        <TitleStyled level={2}>Créer un compte</TitleStyled>
        <Form
          name="signup"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            name="username"
            label="Nom d'utilisateur"
            rules={[{ required: true, message: "Veuillez entrer votre nom d'utilisateur !" }]}
          >
            <StyledInput placeholder="Entrez votre nom d'utilisateur" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Veuillez entrer votre email !" },
              { type: "email", message: "Email invalide !" }
            ]}
          >
            <StyledInput placeholder="Entrez votre email" />
          </Form.Item>
          <Form.Item
            name="telephone"
            label="Telephone"
            rules={[
              { required: true, message: "Veuillez entrer votre numero de telephone !" },
              { type: "text", message: "Telephone invalide !" }
            ]}
          >
            <StyledInput placeholder="Entrez votre numero de telephone" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mot de passe"
            rules={[{ required: true, message: "Veuillez entrer votre mot de passe !" }]}
          >
            <StyledInput.Password style={{ height: "3rem" }} placeholder="Entrez votre mot de passe" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirmez le mot de passe"
            rules={[
              { required: true, message: "Veuillez confirmer votre mot de passe !" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Les mots de passe ne correspondent pas !'));
                },
              }),
            ]}
          >
            <StyledInput.Password style={{ height: "3rem" }} placeholder="Confirmez votre mot de passe" />
          </Form.Item>
          <Form.Item>
            <StyledButton type="primary" htmlType="submit">Créer un compte</StyledButton>
          </Form.Item>
          <StyledLink href="/login" type="link">Vous avez déjà un compte ? Connexion</StyledLink>
        </Form>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120vh;
  background-color: #f0f2f5;
`;

const Content = styled.div`
  width: 100%;
  max-width: 600px; /* Augmenté pour rendre le formulaire plus large */
  padding: 60px 50px; /* Ajout de padding en haut et en bas */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

const LogoWrapper = styled.div`
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 100px; /* Ajustez selon la taille de votre logo */
`;

const TitleStyled = styled(Title)`
  margin-bottom: 24px;
  color: #1890ff;
`;

const StyledInput = styled(Input)`
  height: 50px;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  &:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #1890ff;
  border: none;
  &:hover {
    background-color: #40a9ff;
  }
`;

const StyledLink = styled(Button)`
  font-size: 14px;
  color: #1890ff;
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

export default SignupPage;
