import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import TopNavbar from "./components/Nav/TopNavbar";
import Landing from "./screens/Landing.jsx";
import LoginPage from "./screens/LoginPage";
import SignupPage from "./screens/SignupPage";
import SubmitProject from "./screens/SubmitProject";
import Dashboard from "./screens/Dashboard";
import { TranslationProvider } from './TranslationContext';
import Investments from './screens/Investments';
import { Helmet } from "react-helmet";
import MarketingForm from './screens/MarketingSubmit';
import DesignWebForm from './screens/WebDesign.js';

// Composant pour gérer l'affichage conditionnel de TopNavbar
const Layout = ({ children }) => {
  const location = useLocation();
  const showNavbar = !['/login', '/register'].includes(location.pathname);

  return (
    <>
      {showNavbar && <TopNavbar />}
      {children}
    </>
  );
};

export default function App() {
  return (
    <>
    <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <TranslationProvider>
        <Router>
          <Layout>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<SignupPage />} />
              <Route path="/" element={<Landing />} />
              <Route path="/ap" element={<SubmitProject />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/inv" element={<Investments />} />
              <Route path="/mf" element={<MarketingForm />} />
              <Route path="/wdf" element={<DesignWebForm />} />
            </Routes>
          </Layout>
        </Router>
      </TranslationProvider>
    </>
  );
}
