import React from "react";
import styled from "styled-components";
import { FaPhoneAlt, FaEnvelope, FaGlobe, FaHome } from 'react-icons/fa';
import { useTranslation } from '../../TranslationContext';

export default function Contact() {
  const { t } = useTranslation();

  return (
    <Wrapper id="contact">
      <div style={{ paddingBottom: "6rem" }} className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Contact</h1>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form>
                <label className="font13">First name:</label>
                <input type="text" id="fname" name="fname" className="font20 extraBold" />
                <label className="font13">Email:</label>
                <input type="text" id="email" name="email" className="font20 extraBold" />
                <label className="font13">Subject:</label>
                <input type="text" id="subject" name="subject" className="font20 extraBold" />
                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" />
              </Form>
              <StyledButtonContainer>
                <StyledButton href=''>Envoyer le message</StyledButton>
              </StyledButtonContainer>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <ContactInfo>
                <ContactItem>
                  <IconWrapper>
                    <FaPhoneAlt />
                  </IconWrapper>
                  <TextWrapper>
                    <h3>Téléphone</h3>
                    <p>+44 77 62 473 434</p>
                  </TextWrapper>
                </ContactItem>
                <ContactItem>
                  <IconWrapper>
                    <FaEnvelope />
                  </IconWrapper>
                  <TextWrapper>
                    <h3>Email</h3>
                    <p>contact@xxlwebtechnology.uk</p>
                  </TextWrapper>
                </ContactItem>
                <ContactItem>
                  <IconWrapper>
                    <FaGlobe />
                  </IconWrapper>
                  <TextWrapper>
                    <h3>Site Web</h3>
                    <p><a href="https://www.xxlwebtechnology.uk" target="_blank" rel="noopener noreferrer">https://www.xxlwebtechnology.uk</a></p>
                  </TextWrapper>
                </ContactItem>
                <ContactItem>
                  <IconWrapper>
                    <FaHome />
                  </IconWrapper>
                  <TextWrapper>
                    <h3>Lieux</h3>
                    <p>3rd Floor 207 Regent Street - London, W1B 3HH</p>
                  </TextWrapper>
                </ContactItem>
              </ContactInfo>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;

const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
`;

const IconWrapper = styled.div`
  font-size: 24px;
  color: #007bff;
  margin-right: 15px;
`;

const TextWrapper = styled.div`
  h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #666;
  }

  a {
    color: #007bff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const StyledButtonContainer = styled.div`
  text-align: left;
`;

const StyledButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #df0202;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    color: #ffffff;
  }
`;
