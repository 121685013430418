import React, { useState } from "react";
import styled from "styled-components";
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import { Select } from 'antd';
import CheckMark from "../../assets/svg/Checkmark";

const { Option } = Select;

export default function PricingTable({ icon, price, title, text, offers, action }) {
  let getIcon;
  const [selectedService, setSelectedService] = useState(icon || "roller");

  // Déterminer l'icône en fonction du service sélectionné
  switch (selectedService) {
    case "roller":
      getIcon = <RollerIcon />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    case "strong":
      getIcon = <img style={{ width: "45px" }} src="./static/strongman.svg" />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }

  // Liens associés à chaque service
  const serviceLinks = {
    roller: [
      { name: null, url: "/ap" }
    ],
    strong: [
      { name: null, url: "https://www.xxl-soluyion.uk" }
    ],
    printer: [
      { name: "SEO (Search Engine Optimization)", url: "/mf" },
      { name: "PPC (Pay-Per-Click)", url: "/mf" },
      { name: "Marketing de contenu", url: "/mf" },
      { name: "Marketing sur les réseaux sociaux", url: "/mf" },
      { name: "Email marketing", url: "/mf" },
      { name: "Publicité display", url: "/mf" },
      { name: "Marketing d'affiliation", url: "/mf" },
      { name: "Analyse des données et reporting", url: "/mf" },
      { name: "Automatisation du marketing", url: "/mf" },
      { name: "Gestion de la réputation en ligne", url: "/mf" },
      { name: "Marketing mobile", url: "/mf" },
      { name: "Marketing vidéo", url: "/mf" },
      { name: "E-commerce et gestion de la conversion", url: "/mf" }
    ],
    browser: [
      { name: "Lien 1", url: "/ap" },
      { name: "Lien 2", url: "/ap2" }
    ],
    monitor: [
      { name: null, url: "/wdf" }
    ]
  };

  // Récupérer les liens associés au service sélectionné
  const links = serviceLinks[selectedService] || [];

  // Rediriger vers l'URL sélectionnée
  const handleLinkSelect = (value) => {
    const selectedLink = links.find(link => link.name === value);
    if (selectedLink) {
      window.location.href = selectedLink.url;
    }
  };

  // Vérifier si le lien a un nom null
  const hasLinkWithNullName = links.some(link => link.name === null);

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        {getIcon}
        <p className="font25 extraBold">{price}</p>
      </div>
      <div style={{ margin: "35px 0" }}>
        <h4 className="font30 extraBold">{title}</h4>
        <p className="font13">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                  {item.cheked ? (
                    <div style={{ minWidth: "20px" }}>
                      <CheckMark />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}></div>
                  )}
                </div>
                <p className="font20 extraBold">{item.name}</p>
              </div>
            ))
          : null}
      </div>

      <div style={{ marginTop: "20px" }}>
        {/* Afficher le bouton "Passez commande" si un lien a un nom null */}
        {hasLinkWithNullName ? (
          <StyledButtonContainer>
            <StyledButton href={links.find(link => link.name === null)?.url || "#"}>Passez commande</StyledButton>
          </StyledButtonContainer>
        ) : (
          <Select
            placeholder="Sélectionnez un lien"
            style={{ width: "100%" }}
            onChange={handleLinkSelect}
          >
            {links.map((link, index) => (
              link.name !== null && (
                <Option key={index} value={link.name}>
                  {link.name}
                </Option>
              )
            ))}
          </Select>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledButtonContainer = styled.div`
  max-width: 150px;
  margin: 30px auto 0 auto;
  text-align: center;
`;

const StyledButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #df0202;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    color: #ffffff;
  }
`;
