import React from "react";
// Sections
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Layout from "../components/layout/Layout";
import Equipe from "../components/Sections/Equipe";
import Investment from "../components/Sections/Investment";

export default function Landing() {
  return (
    <>
      <Layout>
        <Header/>
        <Services />
        <Projects />
        <Equipe/>
        <Blog />
        <Pricing />
        <Investment/>
        <Contact />
      </Layout>
    </>
  );
}


