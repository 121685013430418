// translations.js
export const translations = {
    Français: {
      home: "Accueil",
      services: "Services",
      projects: "Réalisations",
      blog: "Blog",
      pricing: "Tarification",
      contact: "Contact",
      login: "Connexion",
      getStarted: "Créer un compte",
      motIntro: "Solutions web et marketing sur mesure.",
      phraseIntro: "Notre entreprise excelle dans le domaine du développement web et du marketing digital, en offrant des solutions sur mesure qui allient créativité, technologie avancée, et stratégie de communication. Nous nous engageons à transformer la présence en ligne de nos clients en une plateforme dynamique et performante, qui non seulement reflète leur identité de marque, mais aussi engage et convertit leur audience cible. Grâce à une expertise approfondie dans les dernières technologies et techniques de marketing, nous aidons nos clients à se démarquer dans un marché numérique en constante évolution, en leur fournissant des outils puissants pour atteindre et dépasser leurs objectifs commerciaux.",
      pdg: "Président directeur générale",
      moiTwo: "Notre entreprise excelle dans le domaine du développement web et du marketing digital, en offrant des solutions sur mesure qui allient créativité, technologie avancée, et stratégie de communication. Nous nous engageons à transformer la présence en ligne de nos clients en une plateforme dynamique et performante, qui non seulement reflète leur identité de marque, mais aussi engage et convertit leur audience cible. Grâce à une expertise approfondie dans les dernières technologies et techniques de marketing, nous aidons nos clients à se démarquer dans un marché numérique en constante évolution, en leur fournissant des outils puissants pour atteindre et dépasser leurs objectifs commerciaux.",
      nosService: "Nos services",
      serviceInfo: "Nous proposons une gamme complète de services, comprenant le Design Graphique, le Design Web, le Développement Web, l'Audit et le Conseil, ainsi que le Marketing Design, afin de renforcer l'identité visuelle de votre marque et d'optimiser votre présence numérique grâce à des solutions innovantes et des conseils stratégiques.",
      marketting: "Augmenter la notoriété de la marque. Attirer des nouveaux prospects qualifiés",
      titleMarket: "Stratégie de marketing digital",
      titleMetodologie: "La méthodologie",
      metodologie: "Nous évaluons vos performances, identifions les opportunités d'amélioration et vous fournissons des recommandations stratégiques pour optimiser vos opérations et atteindre vos objectifs, afin de vous aider à exceller dans votre secteur.",
      titreWeb: "Conception WEB",
      web: "Nous proposons des services complets de développement web incluant l'hébergement, la conception et la maintenance. Nous créons des sites web attrayants et performants, en veillant à ce que votre présence en ligne soit robuste, sécurisée et continuellement optimisée pour répondre à vos besoins évolutifs.",
      dw: "Développer la communication visuelle et le web design. Création de logo, charte graphique, carte de visite, photographie et vidéo entreprise",
      descBs: "La boutique spécialiste de la santé naturelle et bio",
      desElise: "Qui est une association CATALANE a vocation départementale dont la mission principale est le logement des plus démunis, dans le but de favoriser l’insertion par le logement des personnes qui rencontrent des difficultés financière et/ou sociales",
      deslocimmo: "Qui est un logiciel de gestion pour agence immobilière",
      desWeb: "Qui est une agence communication en France",
      desWt: "Qui est une agence de communication au Cameroun",
      team: "Notre Équipe",
      teamOlo: "Voici les membres talentueux qui composent notre équipe",
      presi: "LE PRÉSIDENT. Gérant de La SCI DU STADE et la SCI LES GLAÏEULS. Président de L'association EEA et de l'association ÉLISÉE. Depuis 2013, Développeur web expérimenté et Spécialiste dans tous les métiers du numérique. Directeur d'agence de la Société de Transports NORBERT DENTRESSANGLE. Président de S.A.S.U. XXL WEB DESIGN CREATION en 2019",
      wd: "WEB DÉVELOPPEUR",
      rd: "RÉDACTEUR PRINCIPAL",
      dev: "DÉVELOPPEUR",
      mcm: "RESPONSABLE MARKETING COMMERCIAL",
      xxlCon: "Qui est une plateforme web de mise en relation entre particuliers et professionnels en france",
      invest: "Investissement",
    },
    English: {
      home: "Home",
      services: "Services",
      projects: "Achievements",
      blog: "Blog",
      pricing: "Pricing",
      contact: "Contact",
      login: "Log in",
      getStarted: "Create an account",
      motIntro: "Custom web and marketing solutions.",
      phraseIntro: "Our company excels in the field of web development and digital marketing, offering tailored solutions that combine creativity, advanced technology, and communication strategy. We are committed to transforming our clients' online presence into a dynamic and high-performing platform that not only reflects their brand identity but also engages and converts their target audience. With deep expertise in the latest technologies and marketing techniques, we help our clients stand out in an ever-evolving digital marketplace, providing them with powerful tools to achieve and surpass their business goals.",
      pdg: "Chief Executive Officer",
      moiTwo: "Our company excels in web development and digital marketing by providing tailored solutions that blend creativity, advanced technology, and effective communication strategies. We are committed to transforming our clients' online presence into a dynamic and high-performing platform that not only reflects their brand identity but also engages and converts their target audience. With in-depth expertise in the latest technologies and marketing techniques, we help our clients stand out in a constantly evolving digital landscape by offering powerful tools to achieve and exceed their business goals.",
      nosService: "Our services",
      serviceInfo: "We offer a comprehensive range of services, including Graphic Design, Web Design, Web Development, Audit and Consulting, and Marketing Design, all aimed at enhancing your brand’s visual identity and digital presence with innovative solutions and strategic insights.",
      marketting: "Increase brand awareness. Attract new qualified leads",
      titleMarket: "Digital marketing strategy",
      titleMetodologie: "Methodology",
      metodologie: "We assess your performance, identify improvement opportunities, and provide strategic recommendations to optimize your operations and achieve your goals, helping you excel in your industry.",
      titreWeb: "Web Development",
      web: "We provide comprehensive web development services that include hosting, design, and maintenance. We create visually appealing and high-performance websites, ensuring your online presence is robust, secure, and continuously optimized to meet your evolving needs.",
      dw: "Develop visual communication and web design, including logo creation, brand identity, business cards, photography, and corporate video production.",
      descBs: "The shop specializing in natural and organic health products",
      desElise: "Which is a Catalan association with a departmental vocation, whose main mission is to provide housing for the most disadvantaged, aiming to promote social integration through housing for people facing financial and/or social difficulties",
      deslocimmo: "What is real estate management software?",
      desWeb: "Which is a communication agency in France",
      desWt: "Which is a communication agency in Cameroon",
      team: "Our Team",
      teamOlo : "Here are the talented members who make up our team",
      presi: "CHAIRMAN: Manager of SCI DU STADE and SCI LES GLAÏEULS. President of the EEA association and the ÉLISÉE association. Since 2013, experienced web developer and specialist in all digital professions. Branch Manager of Société de Transports NORBERT DENTRESSANGLE. Chairman of S.A.S.U. XXL WEB DESIGN CREATION in 2019",
      wd: "WEB DEVELOPER",
      rd: "SENIOR EDITOR",
      dev: "DÉVELOPPER",
      mcm: "MANAGER COMMERCIAL MARKETING",
      xxlCon: "Which is a web platform that puts individuals and professionals in touch with each other in France.",
      invest: "Investment",
    },
  };
  