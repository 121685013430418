import React from "react";
import { Form, Input, Button, Typography } from "antd";
import styled from "styled-components";

const { Title } = Typography;

const LoginPage = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Container>
      <Content>
        <LogoWrapper>
          <a href="/"><Logo src="./logo2.png" alt="Logo" /></a>
        </LogoWrapper>
        <TitleStyled level={2}>Se connecter à XXL Solutions</TitleStyled>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Veuillez entrer votre email !" },
              { type: "email", message: "Email invalide !" }
            ]}
          >
            <StyledInput placeholder="Entrez votre email" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mot de passe"
            rules={[{ required: true, message: "Veuillez entrer votre mot de passe !" }]}
          >
            <StyledInput.Password style={{ height: "3rem" }} placeholder="Entrez votre mot de passe" />
          </Form.Item>
          <Form.Item>
            <StyledButton type="primary" htmlType="submit">Connexion</StyledButton>
          </Form.Item>
          <StyledLink style={{ marginLeft: "-1rem" }} type="link">Mot de passe oublié ?</StyledLink>
          <StyledLink style={{ marginLeft: "-1rem" }} href="/register" type="link">Vous n'avez pas de compte ? Créez-en un</StyledLink>
        </Form>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const Content = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

const LogoWrapper = styled.div`
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 100px; /* Ajustez selon la taille de votre logo */
`;

const TitleStyled = styled(Title)`
  margin-bottom: 24px;
  color: #1890ff;
`;

const StyledInput = styled(Input)`
  height: 50px;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  &:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #1890ff;
  border: none;
  &:hover {
    background-color: #40a9ff;
  }
`;

const StyledLink = styled(Button)`
  font-size: 14px;
  color: #1890ff;
`;

const StyledAnchor = styled.a`
  display: block;
  margin-top: 16px;
  font-size: 14px;
  color: #1890ff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export default LoginPage;
